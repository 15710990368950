html{
  scroll-behavior: smooth
}

.slick-dots li button
{
  font-size:12px !important;
}

.slick-dots li button:before{
  font-size:12px !important;
}


/* ----------------
 COMMON CSS 
 ------------------*/
.font-HK{
  font-family: 'Hanken Grotesk';
}
.font-spartan{
  font-family: "League Spartan", sans-serif;
  /* font-weight: 400; */
}

.font-montserrat{
  font-family: "Montserrat", sans-serif;
  /* font-optical-sizing: auto; */
  font-weight: 500;
  /* font-style: normal;  */
}

.whatsapp-ico{
  fill: white;
  width: 55px;
  height: 55px;
  padding: 3px;
  background-color: #4dc247;
  border-radius: 50%;
  box-shadow: 2px 2px 6px rgba(0,0,0,0.4);
  position: fixed;
  bottom: 20px;
  right : 20px;
  bottom: 78px;
  right: 40px;
  z-index: 100;
}

.whatsapp-ico:hover{
  box-shadow: 2px 2px 11px rgba(0,0,0,0.7);
}

/* ----------------
 NAV BAR CSS 
 ------------------*/
.contact-btn-css{
  color: #EDF5F7;
  line-height: normal;
  border-radius: 30px;
  background-color: #13293D;
  height: 42.049px;
  flex-shrink: 0;
  margin-top: -5px;
  padding: 12px 15px 8px 15px;
  border: 1px #13293D solid;
  -webkit-transition: background-color 0.5s ease-out;
  -moz-transition: background-color 0.5s ease-out;
  -o-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out

}
.contact-btn-css:hover{
  background-color: #6495ED;
  color: #000;
  border: 1px #13293D solid;
  margin-top: -5px;
  font-weight: 400;
  padding: 12px 15px 8px 15px;

}
.vertical-nav-line{
width: 1px;
height: 41px;
background: rgba(222, 221, 235, 0.85);
}
.bg-nav-level01{
  background: #13293D;
  box-shadow: 0 0 0 100vmax #13293D;
  clip-path: inset(0 -100vmax);
}

/* mob nav */

.ul-mob-nav{
  animation: scaleFade 0.25s ease-in-out forwards;
  animation-delay: 0.25s;
  transform: scale(0);
  opacity: 0;
}
@keyframes scaleFade {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}
.li-mob-nav{
  animation: scaleFadeX 0.3s ease-in-out forwards;
  animation-delay: 0.3s;
  transform: scale(0);
  opacity: 0;
}
@keyframes scaleFadeX {
  from {
    transform: translateX(-30%);
    opacity: 0;
  }

  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

/* ----------------
 TW ELEMENT MODAL 
 ------------------*/

.open-bottomup-modal-animation{
  animation: slide-up 0.5s ease-in-out;
}
@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

/* ----------------
 HOME PAGE CSS 
 ------------------*/
 
.hero-shadow-blue{
  text-shadow: 2px 2px #13293D;
}
.hero-shadow-lite-blue{
  text-shadow: 2px 2px #6495ED;
}

.btn-type3{
  box-shadow: 2px 2px #13293D;
  color:  #13293D;
  line-height: normal;
  font-size: 22px;
  border-radius: 30px;
  background-color:rgba(235,235,235,.5) ;
  height: 42.049px;
  flex-shrink: 0;
  margin-top: -5px;
  padding: 8px 25px 8px 25px;
  border: 1px #13293D solid;
  -webkit-transition: background-color 0.5s ease-out;
  -moz-transition: background-color 0.5s ease-out;
  -o-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out
}
.btn-type3:hover{
  box-shadow: 2px 2px #13293D;
  background-color:rgba(100, 149, 237, 0.8) ;
  color: #FFF;
  border: 1px #13293D solid;
  margin-top: -5px;
  font-weight: 400;
  padding: 8px 25px 8px 25px;
}

.btn-type2{
  box-shadow: 2px 2px #13293D;
  color: FFF;
  line-height: normal;
  font-size: 22px;
  border-radius: 30px;
  background-color:rgba(100, 149, 237, 0.8);
  height: 42.049px;
  flex-shrink: 0;
  margin-top: -5px;
  padding: 8px 25px 8px 25px;
  border: 1px #13293D solid;
  -webkit-transition: background-color 0.5s ease-out;
  -moz-transition: background-color 0.5s ease-out;
  -o-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out
}
.btn-type2:hover{
  box-shadow: 2px 2px #13293D;
  background-color: rgba(235,235,235,.5) ;
  color: #13293D;
  border: 1px #13293D solid;
  margin-top: -5px;
  font-weight: 400;
  padding: 8px 25px 8px 25px;
}


.btn-type1{
  box-shadow: 2px 2px #13293d;
  color: #EDF5F7;
  line-height: normal;
  font-size: 22px;
  border-radius: 30px;
  background-color: #13293D;
  height: 42.049px;
  flex-shrink: 0;
  margin-top: -5px;
  padding: 8px 25px 8px 25px;
  border: 1px #13293d solid;
  -webkit-transition: background-color 0.5s ease-out;
  -moz-transition: background-color 0.5s ease-out;
  -o-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out
}

.btn-type1:hover{
  box-shadow: 2px 2px #13293D;
  /* text-shadow: 2px 2px #13293D; */
  background-color: rgba(100, 149, 237, 1);
  color: #fff;
  border: 1px #13293D solid;
  margin-top: -5px;
  font-weight: 400;
  padding: 8px 25px 8px 25px;
}

@media screen and (max-width: 391px) {
  .span1-builder{
    font-size: 24px;
    line-height: 2rem;  
  }
  .span2-builder{
    font-size: 40px;  
  }
  .span1-residential{
    font-size: 18px;
    line-height: 2rem;
  }
  .span2-residential{
    font-size: 40px;
  }
  .value-span1{
    font-size: 18px;
  }
  .value-span2{
    font-size: 14px;
  }
  .listing-card{
    max-width: 285px;
  }
  .listing-card-img{
    width: 220px;
  }
  .brand-partners{
    width:220px;
  }
  .office-cards{
    width: 250px;
  }
  .office-cards-img{
    width: 110px;
    height: 110px;
  }
  .office-cards-hr{
    width: 90px;
  }
  .office-card-margin{
    margin-bottom: 25px;
  }
  .office-card-text{
    font-size: 12px;
  }
  .blog-card-dim{
    height: 340px;
  }
  .blog-card-img{
    width: 200px;
    height: 170px;

  }
  .blog-card-text{
    font-size: 18px;
    width: 210px;
  }
  .blog-card-svg{
    height: 16px;
  }
  .footer-subscribe-input{
    width: 320px;
  }
  .footer-subscribe-btn{
    margin-left: 13.6em;
    width: 96px;
  }
  .footer-hr{
    width: 280px;
  }
  .newsletter-btn{
    font-size: 18px !important;
    height: 36px !important;
    margin-bottom: 0em !important;
  }
  .sm-newsletter-modal{
    width: 320px;
    height: 250px;
  }
}

.btn-red{
  /* box-shadow: 2px 2px #13293d; */
  color: #EDF5F7;
  line-height: normal;
  font-size: 22px;
  border-radius: 30px;
  background-color: #8F2924;
  height: 42.049px;
  flex-shrink: 0;
  margin-top: -5px;
  padding: 8px 25px 8px 25px;
  border: 1px #13293d solid;
  -webkit-transition: background-color 0.5s ease-out;
  -moz-transition: background-color 0.5s ease-out;
  -o-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out
}

.btn-red:hover{
  box-shadow: 2px 2px #13293D;  
  margin: 0 2px 2px 0;
  /* text-shadow: 2px 2px #13293D; */
  /* background-color: #6495ed; */
  color: #FFF;
  border: 1px #13293D solid;
  margin-top: -5px;
  font-weight: 400;
  padding: 8px 25px 8px 25px;
  transition: 0.1s;
}
.btn-red:not(:hover){
  transition: 0.1s;
}


@media screen and (max-width: 1280px){
  .btn-type1{
  font-size: 16px;
  padding: 8px 20px 8px 20px;
  }
  .btn-type1:hover{
    font-size: 16px;
    padding: 8px 20px 8px 20px;}
  
  .btn-type2{
    font-size: 16px;
    padding: 8px 20px 8px 20px;
  }
  .btn-type2:hover{
    font-size: 16px;
    padding: 8px 20px 8px 20px;}
  }

@media screen and (max-width: 1024px){
.btn-type1{
font-size: 14px;
padding: 7px 15px 7px 15px;
}
.btn-type1:hover{
  font-size: 14px;
  padding: 7px 15px 7px 15px;}

.btn-type2{
  font-size: 14px;
  padding: 7px 15px 7px 15px;
}
.btn-type2:hover{
  font-size: 14px;
  padding: 7px 15px 7px 15px;}
}

@media screen and (max-width: 640px){
.btn-type1{
  font-size: 12px;
}
.btn-type1:hover{
  font-size: 12px;
}

.btn-type2{
  font-size: 12px;
}
.btn-type2:hover{
  font-size: 12px;
}
.whatsapp-ico{
  bottom: 50px;
}
.connectivity-svg svg {
  width: 70px;
  height: 70px;
}
.highlights-svg svg {
  width: 28px;
  height: 28px;
}
.highlights-modalsvg svg {
  width: 28px;
  height: 28px;
}
.brands-modalsvg svg{
  width: 28px;
  height: 28px;
}
}

/* ----------------
COMING SOON 
 ------------------*/

.comingSoonBG{
  background-color:hsla(0,0%,100%,1);
  background-image:
  radial-gradient(at 40% 20%, hsla(24,0%,100%,0.99) 0px, transparent 50%),
  radial-gradient(at 80% 0%, hsla(189,100%,56%,0.39) 0px, transparent 50%),
  radial-gradient(at 0% 50%, hsla(355,100%,93%,1) 0px, transparent 50%),
  radial-gradient(at 39% 60%, hsla(64,100%,76%,0.4) 0px, transparent 50%),
  radial-gradient(at 0% 100%, hsla(21,0%,99%,1) 0px, transparent 50%),
  radial-gradient(at 100% 100%, hsla(240,100%,70%,0.51) 0px, transparent 50%),
  radial-gradient(at 0% 0%, hsla(172,100%,50%,0.33) 0px, transparent 50%);  
}
/* ----------------
 OFFICE CARDS CSS 
 ------------------*/
.transition-office-cards {
  transition: background-color 0.5s ease;
}

.transition-office-cards:hover {
  background-color: var(--bg-color);
  transition: background-color 1.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.transition-office-cards:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at var(--mouse-x, 50%) var(--mouse-y, 50%), transparent 20%, var(--bg-color) 30%);
  transform: scale(0);
  transform-origin: var(--mouse-x) var(--mouse-y);
  transition: transform 1.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.transition-office-cards:hover:before {
  transform: scale(calc(var(--radius) / 10));
}
.slider-loop {
  display: flex;
  animation: scrollCarousel 80s linear infinite;
  transition: animation-play-state 0.5s;
  white-space: nowrap;
}
.slider-loop:hover {
  animation-play-state: paused;
}
@keyframes scrollCarousel {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% - 20px));
  }
}
@media (max-width:1025px) {
  .slider-loop{
      animation: scrollCarousel 60s linear infinite;
  }
}
@media (max-width:640px) {
  .slider-loop{
      animation: scrollCarousel 20s linear infinite;
  }
}

.carousel-logo {
  width: auto;
  height: auto;
}

/* ----------------
  404 page CSS 
 ------------------*/
.bg404{
  background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
  background-repeat: no-repeat;
  background-size: cover;
  width: auto; 
}

/* ----------------
  PRODUCT page CSS 
 ------------------*/
.showImgs-btn:hover{
  box-shadow: 2px 2px #13293d;
  margin: 0 2px 2px 0;  
  transition: 0.1s;
}
.showImgs-btn:not(:hover){
  transition: 0.1s;
}

.img-hover{
	opacity: 1;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.img-hover:hover{
	opacity: .7;
}

/* ----------------
Contact Page CSS
---------------- */
.errorMessage{
  color:red;
  font-size: 12px;
}

/* ----------------
BLOG CSS
---------------- */
@media screen and (max-width: 375px) {
  .m-blogs{
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* ----------------
submit your property page CSS
---------------- */
@media screen and (max-width: 1024px) {
  .order1{
    order: 1;
  }
  .order2{
    order: 2;
  }
}

/* ----------------
 GENERAL/COMMON CSS 
 ------------------*/
.card-hover:hover{
  transform: scale(1.005);
  transition: 0.2s;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
